import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import * as APIt from "../../API";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.Audit>[] = [
  {
    id: 'event_id',
    header: 'Event Id',
    cell: item => item.event_id,
    minWidth: 150
  },
  {
    id: 'table_name',
    header: 'Object Type',
    cell: (item) => {
      switch(item.table_name) {
        case 'device_links':
          return 'Device Link'
        case 'device_link_events':
          return 'Device Link Event'
        case 'events':
          return 'Event'
        case 'systems':
          return 'System'
        default:
          return item.table_name
      }
    },
    minWidth: 150
  },
  {
    id: 'Event Timestamp',
    header: 'Transaction Timestamp',
    cell: item => item.action_tstamp_tx,
    minWidth: 150
  },
  {
    id: 'action',
    header: 'Action',
    cell: (item) => {
      switch(item.action) {
        case 'I':
          return 'Insert'
        case 'U':
          return 'Update'
        case 'D':
          return 'Delete'
        default:
          return item.action
      }
    },
    minWidth: 150
  },
  {
    id: 'row_data',
    header: 'Record Data',
    cell: item => item.row_data,
    minWidth: 150
  },
  {
    id: 'changed_fields',
    header: 'Changed Fields',
    cell: item => item.changed_fields,
    minWidth: 150
  },
];

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
