import React, { useState, useEffect } from 'react';
import { useState as useHookState } from '@hookstate/core';
import {
  Button,
  Checkbox,
  Container,
  Form,
  FormField,
  Grid,
  Select,
  SpaceBetween,
  Spinner,
  Toggle
} from '@amzn/awsui-components-react';
import { DeviceLink } from '../../API';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from "../../API";
import { updateDeviceLinkV1 as updateDeviceLinkMutation } from "../../graphql/mutations";
import { UpdatedDeviceLinkInterface } from './TablePanel';
import { CancelUpdateDeviceLinkInterface } from './TablePanel';
import { SelectOptionInterface } from './MainContents';
import { forceAwakensBaseState } from 'src/stores/app';
import { useBundle } from '@amzn/react-arb-tools';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { UserActionNames } from 'src/constants/Constants';

export interface DeviceLinkEditPanelPropsInterface {
  cancelCallback: CancelUpdateDeviceLinkInterface;
  acpDeviceOptions: SelectOptionInterface[];
  acpDeviceOptionsLoading: boolean;
  cameraDeviceOptions: SelectOptionInterface[];
  cameraDeviceOptionsLoading: boolean;
  deviceLinks: APIt.DeviceLink[];
  saveCallback: UpdatedDeviceLinkInterface;
  selectedDeviceLink: DeviceLink;
  systemOptions: SelectOptionInterface[];
}

export const DeviceLinkEditPanel = (props: DeviceLinkEditPanelPropsInterface) => {

  const [acdDeviceName, setAcdDeviceNameValue] = useState<string>(props.selectedDeviceLink.acd_device_name);
  const [acdDeviceOptions, setACDDeviceOptions] = useState<SelectOptionInterface[]>(props.acpDeviceOptions);
  const [acdDeviceType, setAcdDeviceTypeValue] = useState<string>(props.selectedDeviceLink.acd_device_type);
  const [acdParentDeviceId, setAcdParentDeviceIdValue] = useState<number>(props.selectedDeviceLink.acd_parent_device_id);
  const [acdChildDeviceId, setAcdChildDeviceIdValue] = useState<number>(props.selectedDeviceLink.acd_child_device_id);
  const [acdSubchildDeviceId, setAcdSubchildDeviceIdValue] = useState<number>(props.selectedDeviceLink.acd_subchild_device_id);
  const [cameraDeviceOptions, setCameraDeviceOptions] = useState<SelectOptionInterface[]>(props.cameraDeviceOptions);
  const [cameraName, setCameraNameValue] = useState<string>(props.selectedDeviceLink.camera_name);
  const [cameraSystemId, setCameraSystemIdValue] = useState<string>(props.selectedDeviceLink.camera_system_id);
  const [enabledValue, setEnabledValue] = useState<string>(() => props.selectedDeviceLink.enabled!);
  const [filterLinkedCameras, setFilterLinkedCameras] = useState<boolean>(true);
  const [filterLinkedDevices, setFilterLinkedDevices] = useState<boolean>(true);
  const [filteringLinkedCameras, setFilteringLinkedCameras] = useState<boolean>(true);
  const [filteringLinkedDevices, setFilteringLinkedDevices] = useState<boolean>(true);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [idValue, setIdValue] = useState<string>(() => props.selectedDeviceLink.id!);
  const [systemIdValue, setSystemIdValue] = useState<string>(props.selectedDeviceLink.system_id);
  const [systemNameValue, setSystemNameValue] = useState<string>(props.selectedDeviceLink.system_name);

  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.Edit');

  const forceAwakensState = useHookState(forceAwakensBaseState);

  if (idValue !== props.selectedDeviceLink.id) {
    setIdValue(props.selectedDeviceLink.id!);
  }

  const updateDeviceLink = async () => {
    try {
      const response = await API.graphql(graphqlOperation(updateDeviceLinkMutation,
        {
          input:
            {
              id: idValue,
              system_id: systemIdValue,
              acd_device_name: acdDeviceName,
              acd_device_type: acdDeviceType,
              acd_parent_device_id: acdParentDeviceId,
              acd_child_device_id: acdChildDeviceId,
              acd_subchild_device_id: acdSubchildDeviceId,
              camera_name: cameraName,
              camera_system_id: cameraSystemId,
              enabled: enabledValue,
              updated_by: forceAwakensState.username.value
            }
        })) as GraphQLResult<APIt.UpdateDeviceLinkV1Mutation>;
      if (response && response.data && response.data.updateDeviceLinkV1) {
        createUserAction({
          actionName: UserActionNames.UpdateDeviceLink,
          username: forceAwakensState.username.value,
          parameters: JSON.stringify({
            updateData: response.data.updateDeviceLinkV1,
          })
        });
        const updatedDeviceLink = response.data.updateDeviceLinkV1;
        props.saveCallback(updatedDeviceLink);
      }
    } catch (e) {
      createUserAction({
        actionName: UserActionNames.UpdateDeviceLinkError,
        username: forceAwakensState.username.value,
        parameters: JSON.stringify({
          updateError: e,
        })
      });
      console.log(`updateDeviceLink(): exception is ${JSON.stringify(e)}`);
    }
  };

  const cancelBtnHandler = () => {
    props.cancelCallback();
  };

  const saveBtnHandler = () => {
    updateDeviceLink();
  };

  const undoBtnHandler = () => {
    setEnabledValue(props.selectedDeviceLink.enabled);
  };

  const deviceLinkEnabledFieldOnChangeHandler = (detail: any) => {
    detail.checked ? setEnabledValue('Y') : setEnabledValue('N');
  };

  const deviceLinkSystemFieldOnChangeHandler = (detail: any) => {
    setSystemIdValue(detail.selectedOption.value);
    setSystemNameValue(detail.selectedOption.label);
  };

  const deviceLinkAccessControlDeviceFieldOnChangeHandler = (detail: any) => {
    setAcdDeviceNameValue(detail.selectedOption.label);
    setAcdDeviceTypeValue(detail.selectedOption.value.split('-')[0]);
    setAcdParentDeviceIdValue(detail.selectedOption.value.split('-')[1]);
    setAcdChildDeviceIdValue(detail.selectedOption.value.split('-')[2]);
    setAcdSubchildDeviceIdValue(detail.selectedOption.value.split('-')[3]);
  };

  const deviceLinkCameraNameFieldOnChangeHandler = (detail: any) => {
    setCameraNameValue(detail.selectedOption.label);
    setCameraSystemIdValue(detail.selectedOption.value);
  };

  useEffect(() => {
    setFilteringLinkedCameras(true);
    if (filterLinkedCameras) {
      const unlinkedCameraOptions = props.cameraDeviceOptions.filter(c => !props.deviceLinks.find(dl => dl.camera_name === c.label));
      setCameraDeviceOptions(unlinkedCameraOptions);
    } else {
      setCameraDeviceOptions(
        props.cameraDeviceOptions.map(c => {
          const deviceLinks = props.deviceLinks.filter(dl => dl.camera_name === c.label);
          return {
            description: deviceLinks.map(l => l.acd_device_name).join(', '),
            label: c.label,
            value: c.value,
          }
        })
      );
    }
    setFilteringLinkedCameras(false);
  }, [filterLinkedCameras]);

  useEffect(() => {
    setFilteringLinkedDevices(true);
    if (filterLinkedDevices) {
      const unlinkedDeviceOptions = props.acpDeviceOptions.filter(c => !props.deviceLinks.find(dl => dl.acd_device_name === c.label));
      setACDDeviceOptions(unlinkedDeviceOptions);
    } else {
      setACDDeviceOptions(
        props.acpDeviceOptions.map(d => {
          const deviceLinks = props.deviceLinks.filter(dl => dl.acd_device_name === d.label);
          return {
            description: deviceLinks.map(l => l.camera_name).join(', '),
            label: d.label,
            value: d.value,
          }
        })
      );
    }
    setFilteringLinkedDevices(false);
  }, [filterLinkedDevices]);

  useEffect(() => {
    if (!isBundleLoading && initialLoad) {
      const DeviceLinkSystemSelectFieldComponent = document.getElementById("DeviceLinkSystemSelectField");
      const DeviceLinkSystemSelectField = DeviceLinkSystemSelectFieldComponent?.firstChild as HTMLInputElement;
      DeviceLinkSystemSelectField.focus();
      setInitialLoad(false);
    }
  });

  if (isBundleLoading) return <Spinner/>;

  return (
    <Container>
      <Form>
        <SpaceBetween size="xs" direction="vertical">
          <FormField label={bundle.getMessage('system')}>
            <Select
              id="DeviceLinkSystemSelectField"
              onChange={({ detail }) => deviceLinkSystemFieldOnChangeHandler(detail)}
              options={props.systemOptions}
              selectedAriaLabel="Selected"
              selectedOption={{ label: systemNameValue, value: systemIdValue }}
            />
          </FormField>
          <FormField label={bundle.getMessage('access-control-device')}>
            <Grid gridDefinition={[{colspan: 6}, {colspan: 4}]}>
              <Select
                filteringType='auto'
                loadingText='Loading Access Control Devices'
                onChange={({ detail }) => deviceLinkAccessControlDeviceFieldOnChangeHandler(detail)}
                options={acdDeviceOptions}
                selectedAriaLabel="Selected"
                selectedOption={{ label: acdDeviceName, value: acdChildDeviceId.toString() }}
                statusType={props.acpDeviceOptionsLoading || filteringLinkedDevices ? 'loading' : 'finished'}
              />
              <Toggle
                checked={filterLinkedDevices}
                onChange={({ detail }) => setFilterLinkedDevices(detail.checked)}
              >
                {bundle.getMessage('unlinked-devices-only')}
              </Toggle>
            </Grid>
          </FormField>
          <FormField label={bundle.getMessage('camera')}>
            <Grid gridDefinition={[{colspan: 6}, {colspan: 4}]}>
              <Select
                filteringType='auto'
                loadingText='Loading Cameras'
                onChange={({ detail }) => deviceLinkCameraNameFieldOnChangeHandler(detail)}
                options={cameraDeviceOptions.sort((a,b) => a.label > b.label ? 1 : -1)}
                selectedAriaLabel="Selected"
                selectedOption={{ label: cameraName, value: cameraSystemId }}
                statusType={props.cameraDeviceOptionsLoading || filteringLinkedCameras ? 'loading' : 'finished'}
              />
              <Toggle
                checked={filterLinkedCameras}
                onChange={({ detail }) => setFilterLinkedCameras(detail.checked)}
              >
                {bundle.getMessage('unlinked-cameras-only')}
              </Toggle>
            </Grid>
          </FormField>
          <FormField label={bundle.getMessage('enabled')}>
            <Checkbox
              id="DeviceLinkEnabledCheckbox"
              onChange={({ detail }) => deviceLinkEnabledFieldOnChangeHandler(detail)}
              checked={enabledValue === 'Y'}
            >
              {bundle.getMessage('enabled')}
            </Checkbox>
          </FormField>
          <SpaceBetween size="xs" direction="horizontal">
            <Button
              onClick={cancelBtnHandler}
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              onClick={saveBtnHandler}
              variant="primary"
            >
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      </Form>
    </Container>
  );
}