import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { IoVideocam } from 'react-icons/io5';
import { useState as useHookState } from '@hookstate/core';
import {
  Button,
  Checkbox,
  Container,
  Form,
  FormField,
  Grid,
  Multiselect,
  Select,
  SpaceBetween,
  Spinner,
  Toggle,
  Table,
  Popover,
  Box,
} from '@amzn/awsui-components-react';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { createDeviceLinkV1 as createDeviceLinkMutation, createDeviceLinkEventV1 } from "../../graphql/mutations";
import { CreatedDeviceLinkInterface } from './TablePanel';
import { CancelCreateDeviceLinkInterface } from './TablePanel';
import { SelectOptionInterface } from './MainContents';
import * as APIt from "../../API";
import { forceAwakensBaseState } from 'src/stores/app';
import { listEventsV1 } from 'src/graphql/queries';
import { useBundle } from '@amzn/react-arb-tools';
import { UserActionNames } from 'src/constants/Constants';
import { FetchCameraImage } from 'src/utils/FetchCameraImage';

export interface DeviceLinkCreatePanelPropsInterface {
  acpDeviceOptions: SelectOptionInterface[];
  acpDeviceOptionsLoading: boolean;
  cameraDeviceOptions: SelectOptionInterface[];
  cameraDeviceOptionsLoading: boolean;
  cancelCreateCallback: CancelCreateDeviceLinkInterface;
  deviceLinks: APIt.DeviceLink[];
  systemOptions: SelectOptionInterface[];
  saveCallback: CreatedDeviceLinkInterface;
}

export const DeviceLinkCreatePanel = (props: DeviceLinkCreatePanelPropsInterface) => {

  const [acdDeviceName, setAcdDeviceNameValue] = useState<string>();
  const [acdDeviceOptions, setACDDeviceOptions] = useState<SelectOptionInterface[]>(props.acpDeviceOptions);
  const [acdDeviceType, setAcdDeviceTypeValue] = useState<string>();
  const [acdParentDeviceId, setAcdParentDeviceIdValue] = useState<string>();
  const [acdChildDeviceId, setAcdChildDeviceIdValue] = useState<string>();
  const [acdSubchildDeviceId, setAcdSubchildDeviceIdValue] = useState<string>();
  const [cameraDeviceOptions, setCameraDeviceOptions] = useState<SelectOptionInterface[]>(props.cameraDeviceOptions);
  const [enabledValue, setEnabledValue] = useState<string>('Y');
  const [filterLinkedCameras, setFilterLinkedCameras] = useState<boolean>(true);
  const [filterLinkedDevices, setFilterLinkedDevices] = useState<boolean>(true);
  const [filteringLinkedCameras, setFilteringLinkedCameras] = useState<boolean>(true);
  const [filteringLinkedDevices, setFilteringLinkedDevices] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedCameras, setSelectedCameras] = useState<{description: string, label: string, value: string}[]>([]);
  const [systemIdValue, setSystemIdValue] = useState<string>();
  const [systemNameValue, setSystemNameValue] = useState<string>();

  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.Create');

  const forceAwakensState = useHookState(forceAwakensBaseState);

  useEffect(() => {
    if (props.systemOptions.length === 1) {
      setSystemIdValue(props.systemOptions[0].value);
      setSystemNameValue(props.systemOptions[0].label);
    }
  }, [])

  const { timeStamp, blobUrl, cameraImage, loading, fetchImage } = FetchCameraImage();

  const createDeviceLink = async () => {
    setSaving(true);
    for (let selectedCamera of selectedCameras) {
      try {
        const response = await API.graphql(graphqlOperation(createDeviceLinkMutation,
          {
            input:
              {
                system_id: systemIdValue,
                acd_device_name: acdDeviceName,
                acd_device_type: acdDeviceType,
                acd_parent_device_id: acdParentDeviceId,
                acd_child_device_id: acdChildDeviceId,
                acd_subchild_device_id: acdSubchildDeviceId,
                camera_name: selectedCamera.label,
                camera_system_id: selectedCamera.value,
                enabled: enabledValue,
                created_by: forceAwakensState.username.value
              }
          })) as GraphQLResult<APIt.CreateDeviceLinkV1Mutation>;
        if (response && response.data && response.data.createDeviceLinkV1) {
          createUserAction(
            {
              actionName: UserActionNames.CreateDeviceLink,
              username: forceAwakensState.username.value,
              parameters: JSON.stringify(
                {
                  createDeviceLink: response.data.createDeviceLinkV1,
                })
            });
          const createdDeviceLink = response.data.createDeviceLinkV1;
          if (acdDeviceType) await createDefaultDeviceLinkEvents(createdDeviceLink.id, acdDeviceType);
        }
      } catch (error) {
        setSaving(false);
        console.log(`createDeviceLink(): exception is ${JSON.stringify(error)}`);
        createUserAction(
          {
            actionName: UserActionNames.CreateDeviceLinkError,
            username: forceAwakensState.username.value,
            parameters: JSON.stringify(
              {
                createDeviceLinkError: error,
              })
          });
        throw error;
      }
    }
    setSaving(false);
  }

  const cancelBtnHandler = () => {
    props.cancelCreateCallback();
  }

  const saveBtnHandler = async () => {
    await createDeviceLink();
    props.saveCallback();
  }

  const deviceLinkEnabledFieldOnChangeHandler = (detail: any) => {
    detail.checked ? setEnabledValue('Y') : setEnabledValue('N');
  };

  const deviceLinkSystemFieldOnChangeHandler = (detail: any) => {
    setSystemIdValue(detail.selectedOption.value);
    setSystemNameValue(detail.selectedOption.label);
  };

  const deviceLinkAccessControlDeviceFieldOnChangeHandler = (detail: any) => {
    setAcdDeviceNameValue(detail.selectedOption.label);
    setAcdDeviceTypeValue(detail.selectedOption.value.split('-')[0]);
    setAcdParentDeviceIdValue(detail.selectedOption.value.split('-')[1]);
    setAcdChildDeviceIdValue(detail.selectedOption.value.split('-')[2]);
    setAcdSubchildDeviceIdValue(detail.selectedOption.value.split('-')[3]);
  };

  const getEventOptions = async (deviceType: string): Promise<SelectOptionInterface[]> => {
    let eventOptions: SelectOptionInterface[] = [];
    try {
      const response = await API.graphql(graphqlOperation(listEventsV1)) as GraphQLResult<APIt.ListEventsV1Query>;
      let events = response.data?.listEventsV1 as APIt.Event[];
      events = events.filter(el => {
        switch(el.name) {
          case 'Alarm Active':
            if (deviceType === 'alarm_panel_input' || deviceType === 'card_reader_aux_input_1' || deviceType === 'card_reader_aux_input_2')
              return true;
            else
              return false;
          default:
            if (deviceType !== 'alarm_panel_input' && deviceType !== 'card_reader_aux_input_1' && deviceType !== 'card_reader_aux_input_2')
              return true;
            else
              return false;
        }
      });
      eventOptions = events.map((el) => { return ({ label: el.name!, value: el.id! }); });
    } catch(error) {
      console.log(`getEventOptions(): error is ${JSON.stringify(error)}`);
    }
    return eventOptions;
  };

  const createDefaultDeviceLinkEvents = async (deviceLinkId: string, acdDeviceType: string) => {
    console.log(`createDefaultDeviceLinkEvents() deviceLinkId is ${deviceLinkId} acdDeviceType is ${acdDeviceType}`);
    const eventOptions = await getEventOptions(acdDeviceType);
    console.log(`createDefaultDeviceLinkEvents() eventOptions is ${JSON.stringify(eventOptions)}`);
    const events: SelectOptionInterface[] = [];
    switch (acdDeviceType) {
      case 'alarm_panel_input':
      case 'card_reader_aux_input_1':
      case 'card_reader_aux_input_2':
        events.push(...eventOptions.filter(eo => eo.label === 'Alarm Active'));
        break;
      case 'lidar':
        events.push(...eventOptions.filter(eo => eo.label.includes('Intrusion Alert:')));
        break;
      default:
        events.push(...eventOptions.filter(eo => eo.label === 'Access Denied'));
        events.push(...eventOptions.filter(eo => eo.label === 'Anti-Passback Violation'));
        events.push(...eventOptions.filter(eo => eo.label === 'Cabinet Tamper'));
        events.push(...eventOptions.filter(eo => eo.label === 'Door Forced Open'));
        events.push(...eventOptions.filter(eo => eo.label === 'Door Held Open'));
    }
    for (let event of events) {
      console.log(`createDefaultDeviceLinkEvents() event is ${JSON.stringify(event)}`);
      try {
        await API.graphql(graphqlOperation(createDeviceLinkEventV1,
          {
            input:
              {
                event_id: event.value,
                device_link_id: deviceLinkId,
                created_by: forceAwakensState.username.value
              }
          })) as GraphQLResult<APIt.CreateDeviceLinkEventV1Mutation>;
          createUserAction(
            {
              actionName: UserActionNames.CreateDeviceLinkEvent,
              username: forceAwakensState.username.value,
              parameters: JSON.stringify(
                {
                  event_id: event.value,
                  device_link_id: deviceLinkId,
                })
            });
      } catch(error) {
        console.log(`createDefaultDeviceLinkEvents(): error is ${JSON.stringify(error)}`);
        createUserAction(
          {
            actionName: UserActionNames.CreateDeviceLinkEvError,
            username: forceAwakensState.username.value,
            parameters: JSON.stringify(
              {
                event_id: event.value,
                device_link_id: deviceLinkId,
                createDeviceLinkEvErro: error,
              })
          });
      }
    }
  };

  useEffect(() => {
    setFilteringLinkedCameras(true);
    if (filterLinkedCameras) {
      const unlinkedCameraOptions = props.cameraDeviceOptions.filter(c => !props.deviceLinks.find(dl => dl.camera_name === c.label));
      setCameraDeviceOptions(unlinkedCameraOptions);
    } else {
      setCameraDeviceOptions(
        props.cameraDeviceOptions.map(c => {
          const deviceLinks = props.deviceLinks.filter(dl => dl.camera_name === c.label);
          return {
            description: deviceLinks.map(l => l.acd_device_name).join(', '),
            label: c.label,
            value: c.value,
          }
        })
      );
    }
    setFilteringLinkedCameras(false);
  }, [filterLinkedCameras]);

  useEffect(() => {
    setFilteringLinkedDevices(true);
    if (filterLinkedDevices) {
      const unlinkedDeviceOptions = props.acpDeviceOptions.filter(c => !props.deviceLinks.find(dl => dl.acd_device_name === c.label));
      setACDDeviceOptions(unlinkedDeviceOptions);
    } else {
      setACDDeviceOptions(
        props.acpDeviceOptions.map(d => {
          const deviceLinks = props.deviceLinks.filter(dl => dl.acd_device_name === d.label);
          return {
            description: deviceLinks.map(l => l.camera_name).join(', '),
            label: d.label,
            value: d.value,
          }
        })
      );
    }
    setFilteringLinkedDevices(false);
  }, [filterLinkedDevices]);

  if (isBundleLoading) return <Spinner/>;

  return (
    <Container>
      <Form>
        <SpaceBetween size="xs" direction="vertical">
          <FormField label={bundle.getMessage('system')}>
            <Select
              onChange={({ detail }) => deviceLinkSystemFieldOnChangeHandler(detail)}
              options={props.systemOptions}
              selectedAriaLabel="Selected"
              selectedOption={{ label: systemNameValue, value: systemIdValue }}
            />
          </FormField>
          <FormField label={bundle.getMessage('access-control-device')}>
            <Grid gridDefinition={[{colspan: 6}, {colspan: 4}]}>
              <Select
                filteringType='auto'
                loadingText={bundle.getMessage('loading')}
                onChange={({ detail }) => deviceLinkAccessControlDeviceFieldOnChangeHandler(detail)}
                options={acdDeviceOptions}
                selectedAriaLabel="Selected"
                selectedOption={acdDeviceName ? { label: acdDeviceName, value: acdChildDeviceId } : null}
                statusType={props.acpDeviceOptionsLoading || filteringLinkedDevices ? 'loading' : 'finished'}
              />
              <Toggle
                checked={filterLinkedDevices}
                onChange={({ detail }) => setFilterLinkedDevices(detail.checked)}
              >
                {bundle.getMessage('unlinked-devices-only')}
              </Toggle>
            </Grid>
          </FormField>
          <FormField label={bundle.getMessage('camera')}>
            <Grid gridDefinition={[{colspan: 6}, {colspan: 4}]}>
              <Multiselect
                filteringType='auto'
                loadingText={bundle.getMessage('loading')}
                onChange={({ detail }) =>
                  {
                    const selectedOptions = detail.selectedOptions.map(v => { return { description: v.label!, label: v.label!, value: v.value! } });
                    setSelectedCameras(selectedOptions || []);
                  }
                }
                options={cameraDeviceOptions.sort((a,b) => a.label > b.label ? 1 : -1)}
                selectedAriaLabel="Selected"
                selectedOptions={selectedCameras.sort((a,b) => a.label > b.label ? 1 : -1)}
                statusType={props.cameraDeviceOptionsLoading || filteringLinkedCameras ? 'loading' : 'finished'}
              />
              <Toggle
                checked={filterLinkedCameras}
                onChange={({ detail }) => setFilterLinkedCameras(detail.checked)}
              >
                {bundle.getMessage('unlinked-cameras-only')}
              </Toggle>
            </Grid>
          </FormField>
          <FormField label={bundle.getMessage('enabled')}>
            <Checkbox
              onChange={({ detail }) => deviceLinkEnabledFieldOnChangeHandler(detail)}
              checked={enabledValue === 'Y'}
            >
              {bundle.getMessage('enabled')}
            </Checkbox>
          </FormField>
          <SpaceBetween size="xs" direction="horizontal">
            <Button
              onClick={cancelBtnHandler}
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              loading={saving}
              onClick={saveBtnHandler}
              variant="primary"
            >
              {bundle.getMessage('save')}
            </Button>
            </SpaceBetween>
          {selectedCameras.length > 0 && (
            <Table
              columnDefinitions={[
                {
                  id: 'deviceId',
                  header: bundle.getMessage('access-control-device'),
                  cell: item => item.deviceId,
                },
                {
                  id: 'cameraName',
                  header: bundle.getMessage('camera'),
                  cell: item => (
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item.cameraName}
                        <Popover
                          triggerType="custom"
                          dismissButton={false}
                          size= "large"
                          content={
                            <Box>
                              {loading ? (
                                <Spinner/>
                              ) : (
                                cameraImage && (
                                  <>
                                    <img
                                      src={ cameraImage }
                                      style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                    />
                                    <a href={ blobUrl } target="_blank" rel="noopener noreferrer">Click for Full Size</a>
                                    <div>{timeStamp}</div>
                                  </>
                                )
                              )}
                            </Box>
                          }
                        >
                          <IconContext.Provider value={{ style: { verticalAlign: 'middle', cursor: 'pointer', marginLeft: '8px', width: '16px', height: '16px' } }}>
                            <IoVideocam onClick={() => fetchImage(item.value)} />
                          </IconContext.Provider>
                        </Popover>
                      </div>
                    </div>
                  ),
                },
              ]}
              items={selectedCameras.map(camera => ({
                deviceId: acdDeviceName,
                cameraName: camera.label,
                description: camera.description,
                label: camera.label,
                value: camera.value
              }))}
              trackBy="cameraName"
            />
          )}
        </SpaceBetween>
      </Form>
    </Container>
  );
}
